import { Center, Space, Text } from "@mantine/core"

type Props = {
  text?: string
  noSpace?: boolean
}

export function ErrorState(props: Props) {
  const text = props.text ?? "Něco se pokazilo. Zkuste to prosím později."

  return (
    <>
      {props.noSpace ? null : <Space h={20} />}

      <Center>
        <Text size="sm" ta="center" c="dimmed">
          {text}
        </Text>
      </Center>

      {props.noSpace ? null : <Space h={20} />}
    </>
  )
}

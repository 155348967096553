import { Center, Loader, Space } from "@mantine/core"

type Props = {
  noSpace?: boolean
}

export function LoadingState(props: Props) {
  return (
    <>
      {props.noSpace ? null : <Space h={20} />}

      <Center>
        <Loader />
      </Center>

      {props.noSpace ? null : <Space h={20} />}
    </>
  )
}

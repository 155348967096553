import { resolveQueriesState } from "@deltagreen/utils"
import { createContext, PropsWithChildren, useContext } from "react"

import { api, RouterOutputs } from "../utils/api"
import { ErrorState } from "./ErrorState"
import { LoadingState } from "./LoadingState"

type AppConfigContextValue = {
  env: RouterOutputs["config"]["appConfig"]["env"]
}

const AppConfigContext = createContext<AppConfigContextValue | undefined>(undefined)

export function useAppConfig() {
  const ctx = useContext(AppConfigContext)
  if (!ctx) {
    throw new Error("AppConfigContext is not available")
  }

  return ctx
}

export function AppConfigProvider(props: PropsWithChildren<unknown>) {
  const appConfigQuery = api.config.appConfig.useQuery({}, { staleTime: 1000 * 60 * 60 })

  const { isError, isLoading } = resolveQueriesState([appConfigQuery])
  if (isLoading) {
    return <LoadingState />
  }

  if (isError || !appConfigQuery.data) {
    return <ErrorState />
  }

  return (
    <AppConfigContext.Provider value={{ env: appConfigQuery.data.env }}>{props.children}</AppConfigContext.Provider>
  )
}
